import {css} from 'lit-element';
import {Theme} from '@furo/framework/src/theme.js'
import {FBP} from '@furo/fbp';
import {FuroDataObject} from '@furo/data/src/furo-data-object.js'

/**
 * `parse-dir`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-parse-dir
 * @appliesMixin FBP
 */
class ParseDir extends FBP(FuroDataObject) {
  constructor(props) {
    super(props);
    this.type = 'tree.Tree';
  }

  async parse(FileSystemHandle) {
    // read config
    let confHandle;
    try {
      confHandle = await FileSystemHandle.getFile('furo.spec.conf.json');
    } catch (e) {
      /**
       * @event error
       * Fired when
       * detail payload:
       */
      const customEvent = new Event('config-error', {composed: true, bubbles: true});
      customEvent.detail = `### File **furo.spec.conf.json** not found:\n\n\nPlease ensure that you open a spec project \n\n\n ${e.message}`;
      this.dispatchEvent(customEvent);
      return;
    }

    const conf = await confHandle.getFile();
    const FuroSpecConf = JSON.parse(await conf.text());

    let specDirHandle;
    if (FuroSpecConf.spec_dir.replace('./', '') !== '') {
      specDirHandle = await this._getPath(
        FuroSpecConf.spec_dir.replace('./', '').split('/'),
        FileSystemHandle,
      );
    } else {
      specDirHandle = FileSystemHandle;
    }

    if (!specDirHandle.isDirectory) {
      // eslint-disable-next-line no-console
      console.error('spec directory not found');
      return;
    }

    // read the contents of the spec dir
    // create root node
    this.tree = {
      id: 'tree',
      display_name: null,
      secondary_text: null,
      description: null,
      root: {
        id: specDirHandle.name,
        display_name: specDirHandle.name,
        secondary_text: 'Project root and base types',
        description: '',
        file_system_handle: specDirHandle,
        icon: 'folder-open',
        panel: 'edit',
        key_words: '',
        has_error: false,
        open: true,
        link: {
          rel: '',
          method: '',
          href: '',
          type: 'Overview',
          service: '',
        },
        is_group_label: false,
        children: [],
      },
    };

    await this._parseRecursive(specDirHandle, this.tree.root);

    const parseImports = async function parseImports() {
      for (const dir of FuroSpecConf.import_spec_dirs) {
        // eslint-disable-next-line no-await-in-loop
        const handle = await this._getPath(dir.split('/'), FileSystemHandle);

        // add base components to tree
        // eslint-disable-next-line no-await-in-loop
        this.tree.root.children.push(await this.addBaseComponentsToTree(handle, this.tree.root));
      }
    };

    // parse FuroSpecConf.import_spec_dirs config entry
    if (FuroSpecConf.import_spec_dirs && FuroSpecConf.import_spec_dirs.length > 0) {
      await parseImports.call(this);
    } else {
      /**
       * @event no-additional-imports
       * Fired when import_spec_dirs is not set.
       * detail payload:
       */
      const customEvent = new Event('no-additional-imports', {composed: true, bubbles: true});
      this.dispatchEvent(customEvent)
    }

    // build the tree object
    this.data.injectRaw(this.tree);

    /**
     * @event tree-complete
     * Fired when tree object is ready to bind
     * detail payload:
     */
    const customEvent = new Event('tree-complete', {composed: true, bubbles: true});
    customEvent.detail = this.data;
    this.dispatchEvent(customEvent);
  }

  async _getPath(pathArray, FileSystemHandle) {
    const dir = pathArray.shift();
    const handle = await FileSystemHandle.getDirectory(dir);
    if (pathArray.length > 0) {
      const p = await this._getPath(pathArray, handle);
      return p;
    }
    return handle;
  }

  async _parseRecursive(FileSystemHandle, node) {
    // Read directory contents.
    for await (const entry of FileSystemHandle.getEntries()) {
      // entry is a FileSystemFileHandle or a FileSystemHandle.
      // put the FileSystemFileHandle in the description field
      const childnode = {
        id: `${node.id}_${entry.name.split('.').join('_')}`,
        display_name: entry.name,
        secondary_text: '',
        description: '',
        file_system_handle: entry,
        icon: 'folder-open',
        panel: 'edit',
        key_words: '',
        has_error: false,
        open: true,
        link: {
          rel: '',
          method: '',
          href: '',
          type: 'tree.Folder',
          service: '',
        },
        is_group_label: false,
        children: [],
      };

      if (entry.name.endsWith('.spec')) {
        node.children.push(childnode);

        if (entry.name.endsWith('type.spec')) {
          // default icon for type
          childnode.icon = 'editor:text-fields';
          childnode.link.type = 'spec.Type';

          if (entry.name.endsWith('entity.type.spec')) {
            childnode.icon = 'av:web-asset';
          }

          if (entry.name.endsWith('collection.type.spec')) {
            childnode.icon = 'view-list';
          }
        }
        if (entry.name.endsWith('service.spec')) {
          childnode.icon = 'cloud-queue';
          childnode.link.type = 'spec.Service';
        }
      } else if (entry.isDirectory) {
        node.children.push(childnode);
        childnode.icon = 'folder-open';
        await this._parseRecursive(entry, childnode);
      }
    }
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ParseDir') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  async addBaseComponentsToTree(FileSystemHandle) {
    const n = {
      id: FileSystemHandle.name,
      display_name: FileSystemHandle.name,
      secondary_text: '',
      description: '',
      file_system_handle: FileSystemHandle,
      icon: 'folder-open',
      panel: 'edit',
      key_words: '',
      has_error: false,
      open: true,
      link: {
        rel: '',
        method: '',
        href: '',
        type: 'Overview',
        service: '',
      },
      is_group_label: false,
      children: [],
    };

    await this._parseRecursive(FileSystemHandle, n);
    return n;
  }
}

window.customElements.define('parse-dir', ParseDir);
