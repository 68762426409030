import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';
import '@furo/util/src/furo-keydown.js'
import './service-outline-field.js';

/**
 * `service-outline`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-service-outline
 * @appliesMixin FBP
 */
class ServiceOutline extends FBP(LitElement) {
  constructor() {
    super();
    this.field = {};
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      myBool: { type: Boolean },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * expects a data object of type spec.Service
   * @param specServiceDataObject
   */
  bindData(specServiceDataObject) {
    this.field = specServiceDataObject;
    this._FBPTriggerWire('--services', specServiceDataObject.services);

    this.field.addEventListener('field-value-changed', () => {
      this.package = specServiceDataObject.__proto.package;
      this.requestUpdate();
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ServiceOutline') ||
      css`
        :host {
          display: block;
          height: 100%;
          border-right: 1px solid #dddddd;
        }

        :host([hidden]) {
          display: none;
        }

        div {
          font-weight: 900;
          cursor: pointer;
        }

        furo-horizontal-flex {
          padding: 8px;
          box-sizing: border-box;
        }
        .title {
          min-height: 40px;
          margin-bottom: var(--spacing-xxs, 4px);
          padding: 0 var(--spacing-xs);
          font-size: 20px;
          height: 40px;
          line-height: 56px;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <div @-click="^^overview-selected(field)" class="title">${this.package}</div>
        <furo-data-repeat
          flex
          scroll
          ƒ-bind-data="--services"
          repeated-component="service-outline-field"
          ƒ-create-attribute-by-string="--adderTriggered"
        ></furo-data-repeat>

        <div>
          <furo-horizontal-flex>
            <!-- It is a good practice to set a description -->
            <furo-text-input
              condensed
              flex
              label="name for service"
              @-value-changed="((park))"
              trailing-icon="add"
              ƒ-set-value="--adderTriggered(*.null)"
              @-trailing-icon-clicked="--adderTriggered(park),--iconClk"
              ƒ-focus="--iconClk"
            ></furo-text-input>

            <furo-keydown key="Enter" @-key="--adderTriggered(park)"></furo-keydown>
          </furo-horizontal-flex>
        </div>
      </furo-vertical-flex>
    `;
  }
}

window.customElements.define('service-outline', ServiceOutline);
