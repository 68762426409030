// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

/**
 * Defines a field in the furo spec
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class StringMapItem extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // Key of the map item
      mapKey: {
        type: String,
        attribute: 'map-key',
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    this._FBPTraceWires();
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this.field = data;
    this.mapKey = data._name;
    // add form for type

    const type = data['@type']._value;
    const segments = type.split('.').join('-').split("/");
    const form = segments[segments.length-1]+ '-form'.toLowerCase();

    const element = document.createElement(form);

    element.bindData(data);
    this.shadowRoot.appendChild(element);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MapItemBaseTheme') ||
      css`
        :host {
          display: block;
        }

        :host[hidden] {
          display: none;
        }

        span {
          line-height: 60px;
          padding-right: 8px;
        }
      `
    );
  }
}

window.customElements.define('google-protobuf-any-repeat-item', StringMapItem);
