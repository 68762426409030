import { LitElement, html, css } from 'lit-element';
import { i18n, Theme } from '@furo/framework/src/furo.js'
import { FBP } from '@furo/fbp';

import '@furo/layout/src/furo-vertical-flex.js'
import '@furo/form';
import '@furo/input';
import '@furo/app/src/furo-card.js'
import '@furo/form/src/furo-form.js'
import '@furo/input/src/furo-icon-button.js'
import '@furo/notification/src/furo-snackbar.js'

/**
 * `view-dashboard`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-dashboard
 * @appliesMixin FBP
 */
class ViewDashboard extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      myBool: { type: Boolean },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          height: 100%;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }

        .content {
          padding: var(--spacing-s);
          background-color: var(--background);
          color: var(--on-surface);
        }


        furo-app-bar-top {
          --furo-app-bar-top-background-light: #ffffff;
          --furo-app-bar-top-background-dark: #fafafa;
          color: #333333;
          line-height: 54px;
          font-size: 18px;
        }

        
        furo-card {
          margin: 0 var(--spacing) var(--spacing) 0;
          width: 330px;
          min-height: 350px;
          float: left;
        }
      `
    );
  }

  /**
   * @private
   * @returns {html}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="main-drawer">
          <div>Furo API Designer</div>
          <furo-empty-spacer></furo-empty-spacer>
          
          <furo-icon-button icon="settings" @-click="--op"></furo-icon-button>
          <furo-snackbar
            position-right
            action-button-text="ignore"
            timeout-in-ms="8000"
            label-text="Settings page is not implemented yet. This message destroys itself in 8 seconds"
            ƒ-show="--op"
          ></furo-snackbar>
        </furo-app-bar-top>
        <div flex scroll class="content">
          <furo-card header-text="${i18n.t('dashboard.tiles.project.title')}">
            <img src="/assets/images/default_hero.png" slot="media" alt="" />
           
            <p>${i18n.t('dashboard.tiles.project.description')}</p>
            <furo-horizontal-flex slot="action">
              <furo-button
                primary
                ƒ-focus="--pageActivated"
                @-click="^^open-project-clkd, --openClkd"
                label="${i18n.t('dashboard.tiles.project.open')}"
              ></furo-button>
              <furo-app-flow event="designer-requested" ƒ-trigger="--openClkd"></furo-app-flow>
            </furo-horizontal-flex>
          </furo-card>
          <furo-card header-text="Important Notice" secondary-text="Because of the usage of the Native File System API, the project mode works in chrome only">
          
          <p>As an alternative you can use the <a href="/typeedit">type editor</a> and <a href="/serviceedit">service editor</a> without having access to the local file system.</p>
          <p>But you are limited to primitive types. Ignore the save, delete,... icons and use the icons (cloud) in the header to read and write your specs from the clipboard.</p>
          
          </furo-card>
<furo-card header-text="Known issues" secondary-text="This are the 'known' issues." >
<p>
<strong>
designer can not follow symlinks
</strong> <br>
this is even not supported in the <a href="https://github.com/WICG/native-file-system/issues/113" rel="noreferrer" target="_blank">native file system api.</a>
</p>
<p><strong>Workaround:</strong> do not use symlinks in your spec project. Sorry for that.</p>
</furo-card>          
          <furo-card header-text="Quickstart" secondary-text="To create a new furo-spec procject do the following steps">
          <p>
          <ul>
          <li>Create a new repository from <a target="_blank" rel="noreferrer" href="https://github.com/theNorstroem/template-furo-spec-project">this template</a></li>
          <li>Add your domain objects to the <code>furo.spec.conf.json > init > packages</code></li>
          <li>run <code>npm run init</code></li>
          <li>edit the specs with the api.designer</li>
          <li>build the artefacts with <code>npm run build</code></li>
          </ul>
          </p>          
</furo-card>


<furo-card header-text="Roadmap" secondary-text="Not ordered">
<p>
<ul>
<li>create type.spec</li>
<li>create service.spec</li>
<li>Assistant to create entities from type specs and collections from entities</li>
<li>Source editor for fields and rpcs</li>
<li style="text-decoration: line-through">install other specs projects to your project via npm, with <strong>cherry picking</strong> aka partial usage of types and services</li>
<li>auto converter for *.proto files to create your *.type.spec file</li>
</ul>
</p>
</furo-card>
<furo-card header-text="Whats new" secondary-text="Changes since last version">

<ul>
<li>fix: deleting of constraints</li>
<li>navigation fixes</li>
<li>install other specs projects to your project via npm</li>
<li>working without file access</li>
<li>whats new section on dashboard</li>
<li>navigation improvements</li>
</ul>

</furo-card>
        </div>
      </furo-vertical-flex>
    `;
  }
}

window.customElements.define('view-dashboard', ViewDashboard);
