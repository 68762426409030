export const Translations = {
  en: {},
  'en-US': {},
  'de-DE': {
    reload: 'Neu laden',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    lorem:
      'This text translates lorem to: At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ',
    install: 'Installieren',
    form: {
      spec: {
        service: {
          lifecycle: {
            header: {
              text: 'Lifecycle',
            },
            secondary: {
              text: '',
            },
          },
          __proto: {
            header: {
              text: 'Protobuf',
            },
            secondary: {
              text: '',
            },
          },
        },
        type: {
          __proto: {
            header: {
              text: 'Protobuf',
            },
            secondary: {
              text: '',
            },
          },
        },
        typeproto: {
          imports: {
            header: {text: 'Imports'},
            secondary: {text: ''},
          },
          options: {
            header: {text: 'Optioins'},
            secondary: {text: ''},
          },
        },
        field: {
          meta: {
            header: {text: "Metas"},
            secondary: {text: "Metas are available for the client and the server"}
          },
          constraints: {
            header: {text: "Field constraints"},
            secondary: {text: "Add constraints for your field. Constraints can be used from the client and server side validators"}
          },
          __ui: {
            header: {text: "UI settings"},
            secondary: {text: "Give hints for the UI Builder"}
          }
        },
        fielduiextension: {
          flags: {
            header: {text: "UI Flags"},
            secondary: {text: "Flags are added by the ui builder to the generated component. This can be something like 'condensed', 'danger',... Please refer to the component documentation"}
          },

        }
      },
      furo: {
        fieldmeta: {
          options: {
            header: {text: "Options for the meta"},
            secondary: {text: ""}
          }
        },
        fieldoption: {
          flags: {
            header: {text: "Flags"},
            secondary: {text: "add flags to your option."}
          },
          list: {
            header: {text: "List"},
            secondary: {text: "Add items to the list here. This can be useful for dropdowns with fixed values, which does not come from the server. Usualy you will add a furo.Optionitem."}
          }
        }
      }
    },
    dashboard: {
      tiles: {
        project: {
          title: 'Project',
          description: 'Open a furo-spec project and start editing the specs',
          open: 'open',
        },
      },
    },
  },
  de: {
    save: 'Speichern',
    reload: 'Neu laden',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    install: 'Installieren',
  },
  'it-CH': {},
  'fr-CH': {},
};

// wire the defaults
Translations['de-CH'] = Translations['de-DE'];
