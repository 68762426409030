import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/furo.js'
import { FBP } from '@furo/fbp';

import '@furo/layout/src/furo-vertical-flex.js'
import '@furo/form';
import '@furo/input';
import '@furo/app/src/furo-card.js'
import '@furo/form/src/furo-form.js'
import '@furo/navigation/src/furo-tree.js'
import '@furo/input/src/furo-icon-button.js'
import '@furo/notification/src/furo-snackbar.js'
import '@furo/navigation/src/furo-panel-coordinator-tabs.js'
import '@furo/route/src/furo-panel-coordinator.js'
import '@furo/notification/src/furo-banner-display.js'
import '@furo/notification/src/furo-banner.js'
import '@furo/util/src/furo-keydown.js'
import '@furo/util/src/furo-navigation-pad.js'
import '../analyzer/file-system-tree.js';

/**
 * `view-tree`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-tree
 * @appliesMixin FBP
 */
class ViewApidesigner extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    /**
     * Register hook on wire --searchUnfocus to
     * disable the treePseudoFocus
     */
    this._FBPAddWireHook('--searchUnfocus', () => {
      this.treePseudoFocus = false;
    });

    /**
     * Register hook on wire --treeClicked to
     * enable the treePseudoFocus
     */
    this._FBPAddWireHook('--treeClicked', () => {
      this.treePseudoFocus = true;
    });

    /**
     * Register hook on wire --searchFocused to
     * enable the treePseudoFocus
     */
    this._FBPAddWireHook('--searchFocused', () => {
      this.treePseudoFocus = true;
    });
  }

  static get properties() {
    return {
      /**
       * simulates the focus for the tree, because we focus the search field
       */
      treePseudoFocus: { type: Boolean },
    };
  }

  openProject() {
    this._FBPTriggerWire('--openDir');
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          height: 100%;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }

        .content {
          background-color: var(--surface);
          color: var(--on-surface);
        }

        .treesearch {
          border-right: 1px solid #dddddd;
        }

        furo-vertical-flex {
          height: 100%;
        }

        furo-panel-coordinator-tabs {
          border-bottom: 1px solid #dddddd;
        }

        /** set the banner icon color to danger color */
        furo-banner-display {
          --furo-icon-fill-color: var(--danger);
        }

        .spacer {
          padding: var(--spacing-s) var(--spacing-s) 0 var(--spacing-s);
        }
        furo-search-input {
          width: 100%;
        }


        furo-app-bar-top {
          --furo-app-bar-top-background-light: #ffffff;
          --furo-app-bar-top-background-dark: #fafafa;
          color: #333333;
          line-height: 54px;
          font-size: 18px;
        }

      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="main-drawer">
          <div>API designer</div>
          <furo-empty-spacer></furo-empty-spacer>
          <furo-icon-button icon="folder-open" @-click="--openDir" title="Open Project"></furo-icon-button>
        </furo-app-bar-top>
        <div flex scroll class="content">
          <furo-split-view>
            <furo-vertical-flex slot="master" class="treesearch">
              <furo-keydown prevent-default shift key="ArrowRight" @-key="--expandFocused"></furo-keydown>
              <furo-keydown prevent-default shift key="ArrowLeft" @-key="--collapseFocused"></furo-keydown>
              <furo-navigation-pad ignored-keys="Escape" @-navigated="--navpadTreeSearch"></furo-navigation-pad>
              <div class="spacer">
                <furo-search-input float condensed autofocus @-blur="--searchUnfocus" @-focusin="--searchFocused"  label="find" leading-icon="search" @-click="--searchClicked" ƒ-focus="--searchClicked, --treeClicked" @-value-changed="--searchTerm">
                </furo-search-input>
              </div>
            <furo-tree
                    flex
                    ƒ-bind-data="--tree"
                    ?focused="${this.treePseudoFocus}"
                     @-click="--treeClicked"
                    ƒ-search="--searchTerm"
                    ƒ-expand-focused-recursive="--expandFocused"
                    ƒ-collapse-focused-recursive="--collapseFocused"
                    ƒ-trigger-navigation="--navpadTreeSearch"
                    root-as-header
                    tabindex="-1"
                    @-node-selected="--nodeSelected"
            ></furo-tree>
            </furo-vertical-flex>
            <furo-vertical-flex>

              <furo-panel-coordinator-tabs
                      ƒ-inject-tabs="--panelChanges"
                      ƒ-trigger-navigation="--navpadPanelTabs"
              >
                <furo-navigation-pad @-navigated="--navpadPanelTabs"></furo-navigation-pad>
              </furo-panel-coordinator-tabs>
              
              <furo-banner-display>
                <furo-banner ƒ-set-text="--error" ƒ-show="--error" icon="info-outline"></furo-banner>
              </furo-banner-display>
              
              <furo-pages scroll default="default" ƒ-activate-page="--page" @-escape="--escapeOnPanel">
                <div name="default">
                  Project default page
                </div>

                <furo-panel-coordinator ƒ-show-page="--nodeSelected"
                                        @-panels-changed="--panelChanges"></furo-panel-coordinator>
            </furo-vertical-flex>
            </furo-pages>
          </furo-split-view>
        </div>
      </furo-vertical-flex>


      <file-system-tree ƒ-open-dir="--openDir" @-object-ready="--tree" @-config-error="--error"></file-system-tree>
      
    `;
  }
}

window.customElements.define('view-apidesigner', ViewApidesigner);
