import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

import { NodeEvent } from '@furo/framework/src/EventTreeNode.js'

/**
 * `data-object-file-writer`
 * Listens to save-requested events in data-object and saves the content of _fileContentJSON
 *
 * @customElement
 * @demo demo-data-object-file-writer
 * @appliesMixin FBP
 */
class DataObjectFileWriter extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  bindData(dataObject) {
    dataObject.addEventListener('save-requested', async event => {
      const treeNode = event.detail;

      try {
        const filehandle = treeNode.file_system_handle._value;
        const fileWriter = await filehandle.createWriter();
        await fileWriter.write(
          0,
          new Blob([JSON.stringify(treeNode._fileContentJSON, null, 2)], {
            type: 'application/json',
          }),
        );
        await fileWriter.close();
        treeNode.dispatchNodeEvent(new NodeEvent('cleared', this, false));
      } catch (err) {
        /**
         * @event write-failed
         * Fired when writing failed
         * detail payload: err
         */
        const customEvent = new Event('write-failed', { composed: true, bubbles: true });
        customEvent.detail = err;
        this.dispatchEvent(customEvent);

        treeNode.dispatchNodeEvent(new NodeEvent('has-error', this, false));
      }
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('DataObjectFileWriter') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <p>Hej, welcome</p>
    `;
  }
}

window.customElements.define('data-object-file-writer', DataObjectFileWriter);
