// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js'
import { Env } from '@furo/framework/src/furo.js'
import '@furo/data-input';
import '@furo/form';
import '@furo/app/src/furo-tooltip.js'
import '../generated_components/spec/spec-fieldproto-form.js';
import '../generated_components/spec/spec-fielduiextension-form.js';
import './custom-furo-fieldmeta-form.js';
import './custom-furo-fieldconstraint-map.js';
import './spec-field-source-view.js';

/**
 * Defines a field in the furo spec
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class CustomSpecFieldForm extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // Header text to label the form
      headerText: {
        type: String,
        attribute: 'header-text',
      },
      // Secondary text for a detailed description
      secondaryText: {
        type: String,
        attribute: 'secondary-text',
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  updateTypesFromImports(imports) {
    // base types https://developers.google.com/protocol-buffers/docs/proto3#scalar
    this._types = [
      'string',
      'bool',
      'float',
      'double',
      'bytes',
      'int32',
      'int64',
      'uint32',
      'uint64',
      'sint32',
      'sint64',
      'fixed32',
      'fixed64',
      'sfixed32',
      'sfixed64',
    ];

    // add types from env
    if (Env.availableTypes) {
      Env.availableTypes.forEach(t => {
        this._types.push(t);
      });
    }

    // make up map<string,xxx>
    const l = this._types.length;
    for (let i = 0; i < l; i += 1) {
      this._types.push(`map<string,${this._types[i]}>`);
    }

    imports.repeats.forEach(imp => {
      const segments = imp._value.replace('.proto', '').split('/');
      const t = segments[segments.length - 1];
      // uppercase first letter in last segment
      segments[segments.length - 1] = t.charAt(0).toUpperCase() + t.slice(1);
      this._types.push(segments.join('.'));
    });

    this._FBPTriggerWire(
      '--types',
      this._types.map(t => ({ id: t, display_name: t })),
    );
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this.field = data;

    this._FBPTriggerWire('--data', data);

    this.updateTypesFromImports(data.__parentNode.__parentNode.__proto.imports);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('FormBaseTheme') ||
      css`
        :host {
          display: block;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex>
        <furo-form
          flex
          
          header-text="${this.headerText ? this.headerText : ''}"
          secondary-text="${this.secondaryText ? this.secondaryText : ''}"
        >
          <!-- It is a good practice to set a description -->
          <furo-form-layouter four breakpoint-big="600">
            <!-- field: type -->
            <furo-data-collection-dropdown
              condensed
              double
              ƒ-bind-data="--data(*.type)"
              ƒ-inject-list="--types"
              ƒ-focus="--focused"
            ></furo-data-collection-dropdown>
            <!-- field: proto number -->
            <furo-data-number-input
              condensed
              double
              ƒ-bind-data="--data(*.__proto.number)"
              ƒ-focus="--focused"
            >
              <furo-tooltip label="${i18n.t('spec.__proto.tooltip')}"></furo-tooltip>
            </furo-data-number-input>

            <!-- field: description -->
            <furo-data-textarea-input full ƒ-bind-data="--data(*.description)">
            </furo-data-textarea-input>

            <!-- field: constraints -->
            <custom-furo-fieldconstraint-map
              full
              header-text="${i18n.t('form.spec.field.constraints.header.text')}"
              secondary-text="${i18n.t('form.spec.field.constraints.secondary.text')}"
              ƒ-bind-data="--data(*.constraints)"
            ></custom-furo-fieldconstraint-map>

            <!-- field: __ui -->
            <spec-fielduiextension-form
              condensed
              full
              header-text="${i18n.t('form.spec.field.__ui.header.text')}"
              secondary-text="${i18n.t('form.spec.field.__ui.secondary.text')}"
              ƒ-bind-data="--data(*.__ui)"
            ></spec-fielduiextension-form>

            <!-- field: meta -->
            <custom-furo-fieldmeta-form
              condensed
              full
              header-text="${i18n.t('form.spec.field.meta.header.text')}"
              secondary-text="${i18n.t('form.spec.field.meta.secondary.text')}"
              ƒ-bind-data="--data(*.meta)"
            ></custom-furo-fieldmeta-form>
          </furo-form-layouter>
        </furo-form>

        <div style="width: 40%;position: relative">
        <spec-field-source-view style="position: absolute;left:0;right: 0;bottom: 0;top:0" ƒ-bind-data="--data"></spec-field-source-view>
</div>
        
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('custom-spec-field-form', CustomSpecFieldForm);
