import { LitElement, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

/**
 * `furo-file-system-api`
 *  https://web.dev/native-file-system/
 *  https://github.com/WICG/native-file-system/blob/master/EXPLAINER.md
 *
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-furo-file-system-api
 * @appliesMixin FBP
 */
class FuroFileSystemApi extends FBP(LitElement) {
  /**
   *
   * @return {Promise}
   */
  openDir() {
    // Assume we at some point got a valid directory handle.
    const dirRef = window.chooseFileSystemEntries({ type: 'openDirectory' });
    dirRef.then(
      ref => {
        /**
         * @event directory
         * Fired when dir_ref is opened
         * detail payload: FileSystemDirectoryHandle
         */
        const customEvent = new Event('directory', { composed: true, bubbles: true });
        customEvent.detail = ref;
        this.dispatchEvent(customEvent);
      },
      err => {
        /**
         * @event request-aborted
         * Fired when the user aborts the request
         * detail payload:  DOMException
         */
        const customEvent = new Event('request-aborted', { composed: true, bubbles: true });
        customEvent.detail = err;
        this.dispatchEvent(customEvent);
      },
    );
    return dirRef;
  }

  openFile(FileSystemFileHandle) {
    if (FileSystemFileHandle.isFile) {
      const p = FileSystemFileHandle.getFile();
      p.then(
        file => {
          /**
           * @event file-opened
           * Fired when
           * detail payload: File Object
           */
          const customEvent = new Event('file-opened', { composed: true, bubbles: true });
          customEvent.detail = file;
          this.dispatchEvent(customEvent);
        },
        err => {
          /**
           * @event request-aborted
           * Fired when the user aborts the request
           * detail payload:  DOMException
           */
          const customEvent = new Event('open-error', { composed: true, bubbles: true });
          customEvent.detail = err;
          this.dispatchEvent(customEvent);
        },
      );
    }
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('nonVisibleComponent') ||
      css`
        :host {
          display: none;
        }
      `
    );
  }
}

window.customElements.define('furo-file-system-api', FuroFileSystemApi);
