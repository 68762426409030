import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

import { NodeEvent } from '@furo/framework/src/EventTreeNode.js'

import './service-outline.js';
import '../project_components/string-repeat.js';
import '../project_components/string-map.js';

import '../generated_components/spec/spec-field-form.js';
import '../generated_components/spec/spec-rpc-form.js';
import '../generated_components/spec/spec-service-form.js';
import '../project_components/google-protobuf-any-repeat.js';
import '@furo/data/src/furo-data-object.js'
import '@furo/data-input/src/furo-catalog.js'

/**
 * `panel-serviceedit`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-panel-serviceedit
 * @appliesMixin FBP
 */
class PanelServiceedit extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      myBool: { type: Boolean },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    /**
     * Register hook on wire --fieldSelected to
     * switch the sub pages
     */
    this._FBPAddWireHook('--fieldSelected', () => {
      this._FBPTriggerWire('--page', 'field');
    });
    this._FBPAddWireHook('--overviewSelected', () => {
      this._FBPTriggerWire('--page', 'head');
    });

    /**
     * we receive a filesystemhandle with nav_node
     */
    this._FBPAddWireHook('--navNode', fieldNode => {
      /**
       * Attach the close-requested listener to the nav node. So you can trigger a close-requested from another location like tab-bar,...
       */
      this.treeNode = fieldNode;
      fieldNode.addEventListener('close-requested', e => {
        if (this.onCloseRequest(e)) {
          this.closePanel();
        }
      });

      // load file
      if (this.treeNode._fileContentJSON === undefined) {
        // load file if not loaded
        this.loadFile();
      } else {
        // content is already in treenode => inject it
        this.injectRawSpec(this.treeNode._fileContentJSON);
      }

      // listen to changes to notify navigationnode
      this.addEventListener('data-changed-after-inject', d => {
        this.treeNode.dispatchNodeEvent(new NodeEvent('modified', this, false));
        this.treeNode._fileContentJSON = d.detail._value;
      });
    });

    /**
     * Register hook on wire --fileOpened to
     * receive the loaded file
     */
    this._FBPAddWireHook('--fileOpened', file => {
      this.file = file;
      const p = file.text();
      p.then(filecontent => {
        this.treeNode._fileContentJSON = JSON.parse(filecontent);
        this.injectRawSpec(this.treeNode._fileContentJSON);
      });
    });
  }

  loadFile() {
    this._FBPTriggerWire('--fileRequested', this.treeNode.file_system_handle._value);
  }

  saveFile() {
    this.treeNode.dispatchNodeEvent(new NodeEvent('save-requested', this.treeNode, true));
  }

  injectRawSpec(rawSpec) {
    // clear the current data-object
    this._FBPTriggerWire('--init');
    this._FBPTriggerWire('--RawServiceType', rawSpec);
  }

  /**
   * Close the panel
   */
  closePanel() {
    if (this.treeNode) {
      this.treeNode.selectItem();
      this.removePanel();
    }
  }

  /**
   * Callback function to interact with close requests
   * Return a true if closing is allowed or false if not
   *
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  onCloseRequest() {
    return true;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PanelServiceedit') ||
      css`
        :host {
          display: block;
          height: 100%;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }

        furo-pages {
          padding: 16px;
          box-sizing: border-box;
        }

        .buttonbar {
          position: absolute;
          right: var(--spacing-s);
          height: 40px;
          z-index: 1;
        }

        furo-icon-button {
          width: 40px;
          height: 40px;
        }

        furo-icon-button[danger] {
          color: var(--danger, #ff0000);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <div class="buttonbar">
        <furo-icon-button icon="save" @click="${this.saveFile}">
          <furo-tooltip label="save to disk"></furo-tooltip>
        </furo-icon-button>
        <furo-icon-button icon="refresh" @click="${this.loadFile}">
          <furo-tooltip label="reload"></furo-tooltip>
        </furo-icon-button>
        <furo-icon-button icon="content-copy" @-click="--writeClipboardClk">
          <furo-tooltip label="copy spec to clipboard"></furo-tooltip>
        </furo-icon-button>
        <furo-icon-button icon="content-paste" @-click="--getClipboardClk">
          <furo-tooltip label="paste spec from clipboard"></furo-tooltip>
        </furo-icon-button>
        <furo-icon-button icon="close" @click="${this.closePanel}">
          <furo-tooltip label="close without save"></furo-tooltip>
        </furo-icon-button>
        <span>....</span>
        <furo-icon-button icon="delete" danger @click="${this.deleteSpecFile}">
          <furo-tooltip label="delete this spec file"></furo-tooltip>
        </furo-icon-button>
      </div>
      <furo-split-view>
        <service-outline
          slot="master"
          ƒ-bind-data="--ServiceTypeReady"
          @-field-selected="--fieldSelected"
          @-overview-selected="--overviewSelected"
        >
        </service-outline>

        <furo-pages default="head" ƒ-activate-page="--page" scroll>
          <spec-service-form name="head" ƒ-bind-data="--ServiceTypeReady"></spec-service-form>
          <spec-rpc-form
            name="field"
            ƒ-.header-text="--fieldSelected(*._name)"
            ƒ-bind-data="--fieldSelected"
          ></spec-rpc-form>
        </furo-pages>
      </furo-split-view>
      <furo-data-object
        type="spec.Service"
        ƒ-init="--init"
        ƒ-inject-raw="--RawServiceType"
        @-object-ready="--ServiceTypeReady"
      ></furo-data-object>

      <furo-file-system-api
        ƒ-open-file="--fileRequested"
        @-file-opened="--fileOpened"
      ></furo-file-system-api>
    `;
  }
}

window.customElements.define('panel-serviceedit', PanelServiceedit);
