import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';
import './analyze-files.js';
import '../project_components/furo-file-system-api.js';
import './parse-dir.js';
import './data-object-file-writer.js';
import '@furo/route/src/furo-app-flow.js'

/**
 * `file-system-tree`
 *  Produces a tree data object with analysys
 *
 * @customElement
 * @appliesMixin FBP
 */
class FileSystemTree extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Triggers the --openDir wire
   * @param payload
   */
  openDir(payload) {
    this._FBPTriggerWire('--openDir', payload);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('FileSystemTree') ||
      css`
        :host {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <parse-dir
        ƒ-parse="--fileSystemDirHandle"
        @-tree-complete="--treeComplete"
        @-object-ready="--treeObject"
        @-no-additional-imports="--noAdditionalImports"
      ></parse-dir>
      <analyze-files ƒ-analyze="--treeComplete"></analyze-files>
      <furo-file-system-api
        ƒ-open-dir="--openDir"
        @-directory="--fileSystemDirHandle"
      ></furo-file-system-api>

      <!-- trigger flow to open this page when tree is loaded -->
      <furo-app-flow event="tree-completed" ƒ-trigger="--treeComplete"></furo-app-flow>
      <data-object-file-writer ƒ-bind-data="--treeObject"></data-object-file-writer>
      <furo-snackbar timeout-in-ms="3000"   icon="close"  label-text="No additional imports, because import_spec_dirs is not set or empty" close-on-escape=""  ƒ-show="--noAdditionalImports"></furo-snackbar>
    `;
  }
}

window.customElements.define('file-system-tree', FileSystemTree);
