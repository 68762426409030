import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

/**
 * `service-outline-field`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-service-outline-field
 * @appliesMixin FBP
 */
class ServiceOutlineField extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      draggable: { type: String, reflect: true },
      dragover: { type: Boolean, reflect: true },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();

    this.addEventListener('dragstart', this.handleDragStart, false);
    this.addEventListener('dragenter', this.handleDragEnter, false);
    this.addEventListener('dragover', this.handleDragOver, false);
    this.addEventListener('dragleave', this.handleDragLeave, false);
    this.addEventListener('drop', this.handleDrop, false);
    // this.addEventListener('dragend', this.handleDragEnd, false);

    this.draggable = 'true';

    // this._FBPTraceWires()

    /**
     * Register hook on wire --deleteFieldClk to
     * delete the field
     */
    this._FBPAddWireHook('--deleteFieldClk', () => {
      this.field.deleteNode();
    });
  }

  handleDragStart(e) {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData(
      'application/furo-service-edit',
      JSON.stringify({
        index: this.getFieldIndex(),
        data: this.field._value,
        name: this.field._name,
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }
    e.dataTransfer.dropEffect = 'move'; // See the section on the DataTransfer object.
    return false;
  }

  handleDragEnter() {
    // this / e.target is the current hover target.
    this.dragover = true;
  }

  handleDragLeave() {
    // this / e.target is previous target element.
    this.dragover = false;
  }

  handleDrop(e) {
    // this / e.target is current target element.

    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    // See the section on the DataTransfer object.
    this.dragover = false;
    const src = JSON.parse(e.dataTransfer.getData('application/furo-service-edit'));
    this.field.__parentNode.moveNode(src.index, this.getFieldIndex());

    return false;
  }

  getFieldIndex() {
    let index;
    this.field.__parentNode.__childNodes.forEach((e, i) => {
      if (e === this.field) {
        index = i;
      }
    });
    return index;
  }

  /**
   * for later usage
   * handleDragEnd(e) {
   * // this/e.target is the source node.
   * }
   */

  /**
   * bind a field
   * @param field
   */
  bindData(field) {
    this.field = field;
    this.field.addEventListener('field-value-changed', () => {
      this.requestUpdate();
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ServiceOutlineField') ||
      css`
        :host {
          display: block;
          min-height: 40px;
          margin-bottom: var(--spacing-xxs, 4px);
          padding: 0 var(--spacing-xs) var(--spacing-xs) var(--spacing-xs);
          transition: all 0.2s;
          border-radius: 4px;
          border-top: 1px solid #dddddd;
        }

        :host([dragover]) {
          background-color: var(--accent);
        }

        :host([hidden]) {
          display: none;
        }

        furo-horizontal-flex > * {
          line-height: 40px;
          letter-spacing: 0.01785714em;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
        }

        furo-icon {
          width: 16px;
          height: 16px;
        }

        /* for disabled items*/
        furo-icon[off] {
          color: #dddddd;
        }

        .type {
          font-weight: 400;
          font-size: 0.6rem;
          cursor: pointer;
        }

        .danger {
          color: var(--danger);
          padding-left: 8px;
        }

        .fieldname {
          max-width: 212px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex @-click="^^field-selected(field)">
        <div class="fieldname">${this.field._name}</div>
        <div flex></div>
        <div class="danger">
          <furo-icon icon="delete" @-click=":STOP, --deleteFieldClk"
            ><furo-tooltip label="delete field ${this.field._name}"></furo-tooltip
          ></furo-icon>
        </div>
      </furo-horizontal-flex>
      <div class="type" @-click="^^field-selected(field)">${this.field.description}</div>
    `;
  }
}

window.customElements.define('service-outline-field', ServiceOutlineField);
