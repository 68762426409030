// Code generated by @furo/ui-builder. DO NOT EDIT.
import {  LitElement, html, css  } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js';

import "@furo/data-input";
import "@furo/form";
import "./spec-lifecycle-form.js";
import "./spec-typeproto-form.js";

/**
 * Defines a service
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class SpecServiceForm extends FBP(LitElement) {

  
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {

      // Header text to label the form
      headerText: {
        type: String,
        attribute: "header-text",
      },
      // Secondary text for a detailed description
      secondaryText: {
        type: String,
        attribute: "secondary-text",
      },
    }
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d)
  }
  

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }


  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this._FBPTriggerWire('--data', data);
    this.field = data;
  }


  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return Theme.getThemeForComponent("FormBaseTheme") || css`
      
      :host {
        display: block;
      }

      :host(.in-repeater) {
        border-bottom: 1px solid var(--separator, #FAFAFA);
      }

      :host([hidden]) {
        display: none;
      }

    `
  }


  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`

      <!-- It is a good practice to set a description -->
      <furo-form header-text="${this.headerText?this.headerText:""}" secondary-text="${this.secondaryText?this.secondaryText:""}">

        <!-- It is a good practice to set a description -->
        <furo-form-layouter four>

          <!-- field: name -->
          <furo-data-text-input condensed double ƒ-bind-data="--data(*.name)" ƒ-focus="--focused"></furo-data-text-input> 

          <!-- field: version -->
          <furo-data-text-input condensed double ƒ-bind-data="--data(*.version)"></furo-data-text-input> 

          <!-- field: description -->
          <furo-data-textarea-input full ƒ-bind-data="--data(*.description)"></furo-data-textarea-input> 

          <!-- field: lifecycle -->
          <spec-lifecycle-form condensed full header-text="${i18n.t('form.spec.service.lifecycle.header.text')}" secondary-text="${i18n.t('form.spec.service.lifecycle.secondary.text')}" ƒ-bind-data="--data(*.lifecycle)"></spec-lifecycle-form> 

          <!-- field: __proto -->
          <spec-typeproto-form condensed full header-text="${i18n.t('form.spec.service.__proto.header.text')}" secondary-text="${i18n.t('form.spec.service.__proto.secondary.text')}" ƒ-bind-data="--data(*.__proto)"></spec-typeproto-form> 
        </furo-form-layouter> 
      </furo-form> 
    `;
  }
}

window.customElements.define('spec-service-form', SpecServiceForm);

