import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

/**
 * `panel-overview`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-panel-overview
 * @appliesMixin FBP
 */
class PanelOverview extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      myBool: { type: Boolean },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    /**
     * Attach the close-requested listener to the nav node. So you can trigger a close-requested from another location like tab-bar,...
     */
    this._FBPAddWireHook('--navNode', fieldNode => {
      this.treeNode = fieldNode;
      fieldNode.addEventListener('close-requested', e => {
        if (this.onCloseRequest(e)) {
          this.closePanel();
        }
      });
    });
  }

  /**
   * Close the panel
   */
  closePanel() {
    if (this.treeNode) {
      this.treeNode.selectItem();
      this.removePanel();
    }
  }

  /**
   * Callback function to interact with close requests
   * Return a true if closing is allowed or false if not
   *
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  onCloseRequest() {
    return true;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PanelOverview') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <p>Hej, welcome</p>
    `;
  }
}

window.customElements.define('panel-overview', PanelOverview);
