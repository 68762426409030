import { panelRegistry } from '@furo/route/src/lib/panelRegistry.js'

// import panels
import '../typeedit/panel-typeedit.js';
import '../serviceedit/panel-serviceedit.js';
import '../designer/panel-folder.js';
import '../designer/panel-overview.js';

// -- register panels

panelRegistry.registerType('spec.Type', {
  edit: 'panel-typeedit',
});

panelRegistry.registerType('spec.Service', {
  edit: 'panel-serviceedit',
});

panelRegistry.registerType('tree.Folder', {
  edit: 'panel-folder',
});

panelRegistry.registerType('Overview', {
  edit: 'panel-overview',
});
