// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js'

import '@furo/data-input';
import '@furo/form';
import '../generated_components/furo/furo-fieldconstraint-map-item.js';

/**
 * a single fieldconstraint
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class FuroFieldconstraintMap extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // Header text of the form
      headerText: {
        type: String,
        attribute: 'header-text',
      },
      // Secondary text of the form
      secondaryText: {
        type: String,
        attribute: 'secondary-text',
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    /**
     * Register hook on wire --adderTriggered to
     * update the available constraints dropdown list
     */
    this._FBPAddWireHook('--adderTriggered', () => {
      this.updateConstaintsList();
    });
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this._FBPTriggerWire('--data', data);
    this.field = data;
    this.updateConstaintsList();
  }

  updateConstaintsList() {
    const constraints = ['required', 'min', 'max', 'step', 'pattern'];
    // remove used constaints
    this.field.__childNodes.forEach(c => {
      const i = constraints.indexOf(c._name);
      if (i !== -1) {
        constraints.splice(i, 1);
      }
    });

    // the list for the dropdown
    this.available_constraints = constraints.join(',');

    // hide if no constaints are available
    if (constraints.length > 0) {
      // select first item
      // eslint-disable-next-line prefer-destructuring
      this.park = constraints[0];
      this.hideAdder = false;
    } else {
      this.hideAdder = true;
    }
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MapBaseTheme') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        furo-button {
          margin: 12px 0 0 6px;
        }

        furo-horizontal-flex {
          margin-bottom: 6px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <!-- It is a good practice to set a description -->
      <furo-form
        header-text="${this.headerText}"
        secondary-text="${this.secondaryText}"
      ></furo-form>

      <!-- the core of the map item is the form -->
      <furo-data-repeat
        delete-icon="delete"
        repeated-component="furo-fieldconstraint-map-item"
        ƒ-bind-data="--data"
        ƒ-create-attribute-by-string="--adderTriggered"
      ></furo-data-repeat>

      <!-- It is a good practice to set a description -->
      <furo-horizontal-flex ?hidden="${this.hideAdder}">
        <!-- It is a good practice to set a description -->
        <furo-data-collection-dropdown
          @-value-changed="((park))"
          condensed
          list="${this.available_constraints}"
          flex
          label="name for furo.FieldConstraint"
        ></furo-data-collection-dropdown>

        <!-- It is a good practice to set a description -->
        <furo-button outline label="add" @-click="--adderTriggered(park)"></furo-button>
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('custom-furo-fieldconstraint-map', FuroFieldconstraintMap);
