import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

/**
 * `panel-folder`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-panel-folder
 * @appliesMixin FBP
 */
class PanelFolder extends FBP(LitElement) {
  constructor() {
    super();
    this.field = {};
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      myBool: { type: Boolean },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    /**
     * Attach the close-requested listener to the nav node. So you can trigger a close-requested from another location like tab-bar,...
     */
    this._FBPAddWireHook('--navNode', fieldNode => {
      this.treeNode = fieldNode;
      fieldNode.addEventListener('close-requested', e => {
        if (this.onCloseRequest(e)) {
          this.closePanel();
        }
      });
    });
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this.field = data;
    this._FBPTriggerWire('--data', data);
    this.requestUpdate();
  }

  /**
   * Close the panel
   */
  closePanel() {
    if (this.treeNode) {
      this.treeNode.selectItem();
      this.removePanel();
    }
  }

  /**
   * Callback function to interact with close requests
   * Return a true if closing is allowed or false if not
   *
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  onCloseRequest() {
    return true;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PanelFolder') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h1>${this.field.display_name}</h1>
      <h3>Possible features:</h3>
      <p>create type.spec</p>
      <p>create service.spec</p>
      <p>create folder</p>
      <p>rename folder</p>
      <p>delete folder and files</p>
      <p>list specs, and on following types add this actions:
      <ul>        
        <li>type.spec => duplicate</li>
        <li>type.spec => createEntitySpec</li>
        <li>entity.type.spec => createCollection</li>
      </ul>
      </p>


    `;
  }
}

window.customElements.define('panel-folder', PanelFolder);
