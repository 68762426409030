import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';
import { Env } from '@furo/framework/src/furo.js'

/**
 * `analyze-files`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-analyze-files
 * @appliesMixin FBP
 */
class AnalyzeFiles extends FBP(LitElement) {
  constructor() {
    super();
    Env.availableTypes = [];
  }

  analyze(tree) {
    // build queue for types
    this.types = [];
    this.parseRecursive(tree.root);

    if (this.types.length > 0) {
      this._currentType = this.types.pop();
      this._FBPTriggerWire('--fileRequested', this._currentType.file_system_handle._value);
    }
  }

  parseRecursive(node) {
    if (node.file_system_handle._value.isDirectory) {
      // go deeper
      node.children.repeats.forEach(item => {
        this.parseRecursive(item);
      });
    } else if (node.display_name._value.endsWith('type.spec')) {
      this.types.push(node);
    }
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
    /**
     * Register hook on wire --fileOpened to
     * append the file content...
     */
    this._FBPAddWireHook('--fileOpened', file => {
      const p = file.text();
      p.then(filecontent => {
        try {
          this._currentType._fileContentJSON = JSON.parse(filecontent);
          // extract the type...
          const type = `${this._currentType._fileContentJSON.__proto.package}.${this._currentType._fileContentJSON.type}`;

          // add to type env
          Env.availableTypes.push(type);
        } catch (e) {
          this._currentType._fileContentJSON = {};
          this._currentType.has_error._value = true;
        }

        // do the next file
        if (this.types.length > 0) {
          // give the ui a pause
          setTimeout(() => {
            this._currentType = this.types.pop();
            this._FBPTriggerWire('--fileRequested', this._currentType.file_system_handle._value);
          }, 64);
        }
      });
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('AnalyzeFiles') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-file-system-api
        ƒ-open-file="--fileRequested"
        @-file-opened="--fileOpened"
      ></furo-file-system-api>
    `;
  }
}

window.customElements.define('analyze-files', AnalyzeFiles);
