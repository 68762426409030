import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';
import '@furo/util/src/furo-pretty-json.js'
/**
 * `spec-field-source-view`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-spec-field-source-view
 * @appliesMixin FBP
 */
class SpecFieldSourceView extends FBP(LitElement) {
  constructor() {
    super();

    const self = this;
    // eslint-disable-next-line func-names
    this.listen = function() {
      self._FBPTriggerWire('--data', self.field._value);
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
    /**
     * Register hook on wire --showCodeClk to
     *
     */
    this._FBPAddWireHook('--showCodeClk', () => {
      this.setAttribute('flex', '');
    });
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this._FBPTriggerWire('--data', data._value);
    this.field = data;

    this.field.removeEventListener('field-value-changed', this.listen);
    this.field.addEventListener('field-value-changed', this.listen);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('SpecFieldSourceView') ||
      css`
        :host {
          display: block;
          overflow: hidden;
          padding: 0 var(--spacing);
        }

        :host([hidden]) {
          display: none;
        }
        
        furo-pretty-json {
          width: 100%;
          overflow: auto;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-pretty-json class="source" ƒ-inject-data="--data"></furo-pretty-json>
    `;
  }
}

window.customElements.define('spec-field-source-view', SpecFieldSourceView);
