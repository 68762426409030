// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js'

import '@furo/data-input';
import '@furo/form';
import './bool-map-item.js';
/**
 * Defines a field in the furo spec
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class BoolMap extends FBP(LitElement) {
  constructor() {
    super();
    this.field = {};
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // Header text of the form
      headerText: {
        type: String,
        attribute: 'header-text',
      },
      // Secondary text of the form
      secondaryText: {
        type: String,
        attribute: 'secondary-text',
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this._FBPTriggerWire('--data', data);
    this.field = data;
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MapBaseTheme') ||
      css`
        :host {
          display: block;
        }

        :host[hidden] {
          display: none;
        }

        furo-button {
          margin: 12px 0 0 6px;
        }

        furo-horizontal-flex {
          margin-bottom: 6px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <!-- It is a good practice to set a description -->
      <furo-form
        header-text="${this.headerText}"
        secondary-text="${this.secondaryText}"
      ></furo-form>

      <!-- the core of the map item is the form -->
      <furo-data-repeat
        delete-icon="delete"
        repeated-component="bool-map-item"
        ƒ-bind-data="--data"
        ƒ-create-attribute-by-string="--adderTriggered"
      ></furo-data-repeat>

      <!-- It is a good practice to set a description -->
      <furo-horizontal-flex>
        <!-- It is a good practice to set a description -->
        <furo-text-input
          condensed
          flex
          label="key"
          @-value-changed="((park))"
          ƒ-focus="--adderTriggered"
          ƒ-
        ></furo-text-input>

        <!-- It is a good practice to set a description -->
        <furo-button
          outline
          label="add ${this.field._name}"
          @-click="--adderTriggered(park)"
        ></furo-button>
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('bool-map', BoolMap);
