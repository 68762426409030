// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js'
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js'

import '@furo/data-input';
import '@furo/form';
import '../generated_components/furo/furo-fieldoption-form.js';

/**
 * Metas for a field
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class CustomFuroFieldmetaForm extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // Header text to label the form
      headerText: {
        type: String,
        attribute: 'header-text',
      },
      // Secondary text for a detailed description
      secondaryText: {
        type: String,
        attribute: 'secondary-text',
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   *  Bind your furo-data-object event @-object-ready
   * @public
   * @param data
   */
  bindData(data) {
    this._FBPTriggerWire('--data', data);
    this.field = data;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('FormBaseTheme') ||
      css`
        :host {
          display: block;
        }

        :host[hidden] {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <!-- It is a good practice to set a description -->
      <furo-form
        header-text="${this.headerText ? this.headerText : ''}"
        secondary-text="${this.secondaryText ? this.secondaryText : ''}"
      >
        <!-- It is a good practice to set a description -->
        <furo-form-layouter four>
          <!-- field: label -->
          <furo-data-text-input
            condensed
            double
            ƒ-bind-data="--data(*.label)"
            ƒ-focus="--focused"
          ></furo-data-text-input>

          <!-- field: hint -->
          <furo-data-text-input
            condensed
            double
            ƒ-bind-data="--data(*.hint)"
          ></furo-data-text-input>

          <!-- field: default -->
          <furo-data-textarea-input            
            full            
            ƒ-bind-data="--data(*.default)"
          ></furo-data-textarea-input>

          

          <!-- field: readonly -->
          <furo-data-checkbox-input
            condensed
            double
            ƒ-bind-data="--data(*.readonly)"
          ></furo-data-checkbox-input>

          <!-- field: repeated -->
          <furo-data-checkbox-input
            condensed
            double
            ƒ-bind-data="--data(*.repeated)"
          ></furo-data-checkbox-input>

          <!-- field: options -->
          <furo-fieldoption-form
            condensed
            full
            header-text="${i18n.t('form.furo.fieldmeta.options.header.text')}"
            secondary-text="${i18n.t('form.furo.fieldmeta.options.secondary.text')}"
            ƒ-bind-data="--data(*.options)"
          ></furo-fieldoption-form>
        </furo-form-layouter>
      </furo-form>
    `;
  }
}

window.customElements.define('custom-furo-fieldmeta-form', CustomFuroFieldmetaForm);
