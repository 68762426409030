import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { Theme } from '@furo/framework/src/theme.js'
import { Styling } from './configs/styling.js';

import '@furo/input/src/furo-button.js'
import '@furo/route/src/furo-location.js'
import '@furo/route/src/furo-pages.js'
import '@furo/route/src/furo-app-flow.js'
import '@furo/app/src/furo-app-drawer.js'
import '@furo/app/src/furo-app-bar-top.js'
import '@furo/notification/src/furo-snackbar-display.js'
import './menu/main-menu.js';

/**
 * Static imports of the views
 * The lazy imports a below in _FBPReady
 */

import './views/view-apidesigner.js';
import './views/view-dashboard.js';
import './views/view-auth.js';
import './views/view-404.js';

/**
 * `main-stage`
 *
 * @customElement
 * @appliesMixin FBP
 */
class MainStage extends FBP(LitElement) {
  _FBPReady() {
    super._FBPReady();
    /**
     * Register hook on wire --locationChanged to
     * Lazy load parts of the page
     *
     * DO NOT FORGET TO REGISTER THE LAZY LOADED PARTS IN ~/polymer.json => fragments[...]
     *
     */
    this._FBPAddWireHook('--locationChanged', async e => {
      switch (e.pathSegments[0]) {
        case 'typeedit':
          // eslint-disable-next-line no-unused-expressions
          await import('./views/view-typeedit.js');
          break;
        case 'serviceedit':
          // eslint-disable-next-line no-unused-expressions
          await import('./views/view-serviceedit.js');
          break;

        default:
      }
    });
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    const theme = Theme.getThemeForComponent(this.name);
    if (theme) {
      return [theme, Styling.theme];
    }
    // language=CSS
    return [
      css`
        :host {
          height: 100%;
          display: block;
          background: var(--background);
          color: var(--on-background);
        }

        furo-pages {
          height: 100vh;
          overflow: hidden;
        }

        side-navigation {
          background-color: var(--llm-color);
        }
      `,
      Styling.theme,
    ];
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-app-drawer
        name="main-drawer"
        ƒ-close="--locationChanged"
        ƒ-open="--openNavClicked"
        float-breakpoint="2800"
        @-open-drawer-menu-clicked="--openNavClicked"
      >
        <main-menu
          slot="drawer"
          drawer="main-drawer"
          header-text="Title"
          secondary-text="Secondary text"
        ></main-menu>
        <furo-pages
          ƒ-inject-location="--locationChanged"
          default="dashboard"
          @-response-error="--responseError"
        >
          <view-apidesigner name="designer" ƒ-open-project="--openProjectClkd"></view-apidesigner>
          <view-dashboard name="dashboard" @-open-project-clkd="--openProjectClkd"></view-dashboard>
          <view-auth name="auth"></view-auth>
          <view-typeedit name="typeedit"></view-typeedit>
          <view-serviceedit name="serviceedit"></view-serviceedit>

          <view-404 name="404"></view-404>
        </furo-pages>
      </furo-app-drawer>

      <furo-snackbar-display></furo-snackbar-display>
      <furo-location
        url-space-regex="^${window.APPROOT}"
        @-location-changed="--locationChanged"
      ></furo-location>
      <furo-app-flow ƒ-emit="" event="response-error"></furo-app-flow>
    `;
  }
}

window.customElements.define('main-stage', MainStage);
